//mixins

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin vertical-none($position: relative) {
  position: $position;
  top: 0;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

@mixin transition(){
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@mixin transition_slow(){
  -webkit-transition: all 5s ease 0s;
  -moz-transition: all 5s ease 0s;
  -ms-transition: all 5s ease 0s;
  -o-transition: all 5s ease 0s;
  transition: all 5s ease 0s;
}

@mixin color-transition(){
  -webkit-transition: color 500ms ease 0s;
  -moz-transition: color 500ms ease 0s;
  -ms-transition: color 500ms ease 0s;
  -o-transition: color 500ms ease 0s;
  transition: color 500ms ease 0s;
}

// Configuration
@import "bootstrap/functions";

// name that color: https://chir.ag/projects/name-that-color/
$lunar-green: #333a35;
$fuel-yellow: #eca419;
$aths-special: #EDE4D3;
$pearl-bush: #DFD7C7;
$mine-shaft: #272727;
$chicago: #595753;

/* Extra Small devices (mobiles, 320px and up) */
$xs: "(max-width: 767px)";

/* Small devices (tablets, 768px and up) */
$sm: "(min-width: 768px) and (max-width: 991px)";

/* Medium devices (desktops, 992px and up) */
$md: "(min-width: 992px) and (max-width: 1199px)";

/* Large devices (large desktops, 1200px and up) */
$lg: "(min-width: 1200px) and (max-width: 1366px)";

/* Xtra Large devices (large desktops, 1366px and up) */
$xlg: "(min-width: 1367px)";

@import "bootstrap/variables";

$theme-colors: (
  "lunar-green": $lunar-green,
  "fuel-yellow": $fuel-yellow
);

// source: https://fontshub.pro/font/rift-download
@font-face {
  font-family: 'Rift';
  src: url('/assets/fonts/rift/Rift-Medium.eot');
  src: local('Rift Medium'), local('Rift-Medium'),
    url('/assets/fonts/rift/Rift-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/rift/Rift-Medium.woff2') format('woff2'),
    url('/assets/fonts/rift/Rift-Medium.woff') format('woff'),
    url('/assets/fonts/rift/Rift-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rift';
  src: url('/assets/fonts/rift/Rift-Demi.eot');
  src: local('Rift Demi'), local('Rift-Demi'),
    url('/assets/fonts/rift/Rift-Demi.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/rift/Rift-Demi.woff2') format('woff2'),
    url('/assets/fonts/rift/Rift-Demi.woff') format('woff'),
    url('/assets/fonts/rift/Rift-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rift';
  src: url('/assets/fonts/rift/Rift-Bold.eot');
  src: local('Rift Bold'), local('Rift-Bold'),
    url('/assets/fonts/rift/Rift-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/rift/Rift-Bold.woff2') format('woff2'),
    url('/assets/fonts/rift/Rift-Bold.woff') format('woff'),
    url('/assets/fonts/rift/Rift-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// source: https://www.onlinewebfonts.com/search?q=tenso
@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/tenso/Tenso-Light.eot');
  src: local('Tenso Light'), local('Tenso-Light'),
    url('/assets/fonts/tenso/Tenso-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/tenso/Tenso-Light.woff2') format('woff2'),
    url('/assets/fonts/tenso/Tenso-Light.woff') format('woff'),
    url('/assets/fonts/tenso/Tenso-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/tenso/Tenso-Regular.eot');
  src: local('Tenso Regular'), local('Tenso-Regular'),
    url('/assets/fonts/tenso/Tenso-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/tenso/Tenso-Regular.woff2') format('woff2'),
    url('/assets/fonts/tenso/Tenso-Regular.woff') format('woff'),
    url('/assets/fonts/tenso/Tenso-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/tenso/Tenso-Medium.eot');
  src: local('Tenso Medium'), local('Tenso-Medium'),
    url('/assets/fonts/tenso/Tenso-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/tenso/Tenso-Medium.woff2') format('woff2'),
    url('/assets/fonts/tenso/Tenso-Medium.woff') format('woff'),
    url('/assets/fonts/tenso/Tenso-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/tenso/Tenso-Bold.eot');
  src: local('Tenso Bold'), local('Tenso-Bold'),
    url('/assets/fonts/tenso/Tenso-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/tenso/Tenso-Bold.woff2') format('woff2'),
    url('/assets/fonts/tenso/Tenso-Bold.woff') format('woff'),
    url('/assets/fonts/tenso/Tenso-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@import "bootstrap/mixins";
@import "bootstrap/utilities";

// Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/toasts";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/spinners";

// Helpers
@import "bootstrap/helpers";

// Utilities
@import "bootstrap/utilities/api";

html, body {
  display: block;
  height: 100%;
}

// Navbar styling

@include media-breakpoint-down(md) {
  .navbar-collapse {
    border-top: 10px solid #eca419;
  }

  .navbar-nav {
    margin-top: 1rem;
  }
} // media-breakpoint-down(md)

@include media-breakpoint-up(md) {
  .navbar-custom .navbar-nav .nav-link::after {
    transition: bottom ease .25s, background-color ease .25s;
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent;
  }

  .navbar-custom .navbar-nav .nav-link:hover::after {
    bottom: -5px;
    background-color: $fuel-yellow;
  }
} // media-breakpoint-up(md)

.navbar-custom .navbar-nav .nav-link {
  color: white;
  display: inline;
  font-family: "Rift","Arial",sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  margin-right: 2rem;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  &.no_margin {
    margin-right: 0;
  }
}

.navbar-brand {
  margin-right: 3rem;
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: white;
}

.navbar-custom .navbar-toggler {
  color: white;
}

.navbar-custom .navbar-brand {
  color: white;
}

// the stroke is also $fuel-yellow but I have to specify it in rgba
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(236,164,25, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler .navbar-toggler {
  border-color: $lunar-green;
}

.site-header .navbar {
  padding: 20px 0;
  border-bottom: 10px solid $fuel-yellow;
  @include transition();
  @media #{$xs} {
    padding: 0;
  }
}

.site-header .navbar-hide {
  padding: 0;
  border-bottom: 6px solid $fuel-yellow;
}

.navbar-custom .nav_right .nav-link {
  color: $fuel-yellow;
}


// Background styling
.main_bgd {
  background-color: $aths-special;
}

.article_content {
  position: relative;
  margin-top: 110px;
  background-color: #fff;
  padding-bottom: 50px;
  max-width: 1150px;
  @media #{$xs}, #{$sm} {
    margin-top:50px;
  }
  @media #{$md} {
    max-width: 90%;
  }
  a {
    color: $fuel-yellow;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    @include transition();
    &:hover {
      color: $fuel-yellow;
      border-bottom: 2px solid $fuel-yellow;
    }
  }
  section {
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 2rem;
    section {
      padding-top: 2rem;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  img {
    max-width: 100%;
  }
  .col {
    @media #{$xs} {
      padding: 0;
    }
  }
  .article_title {
    padding: 70px 0 50px;
  }
  .post-category {
    padding: 0;
    text-align: center;
  }

  .decorative-icon {
    svg {
      fill: $fuel-yellow;
      width: 24px;
      height: 24px;
    }
    color: blue;
    padding-right: 5px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Rift", "Arial", sans-serif;
    font-weight: 600;
  }

  h1 {
   display: block;
   font-size: 3.8em;
   text-align: center;
   @media #{$xs}, #{$sm} {
      font-size: 2.8em;
    }
  }

  h2 {
    font-size: 2.625em;
    margin-bottom: 2rem;
    @media #{$xs} {
      font-size: 1.8rem;
    }
  }

  p,
  ul li,
  figcaption
  {
    font: 300 1.25rem "Tenso";
    color: $chicago;
    line-height: 1.5;
    &.intro {
      font-size: 1.3rem;
    }
  }

  .reviewed-products-links li {
    line-height: 1.6;
  }

  .pros-cons {
    list-style: none;
  }

  .pros-cons li::before {
    content: "\2022";
    color: $fuel-yellow;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

section .product {
  border: 2px solid $pearl-bush;
  h4 {
    font: 700 1.125rem "Rift";
    margin-bottom: 30px;
    span {
      width: auto;
      margin: 0 auto;
      background-color: $pearl-bush;
      padding: 2px 15px;
    }
  }
  &-body {
    img {
      width: 150px;
    }
  }
  &-title {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font: 700 1.625rem "Rift";
  }
  &-btn {
    background-color: $fuel-yellow;
    color: $lunar-green;
    border: 0px;
    margin-bottom: 1rem;
    font: 700 1.2rem "Rift";
    padding: 0.8rem 1rem;
    &:hover {
      background-color: #fdb52a;
      color: $lunar-green;
      border: 0;
    }
  }
}

p .product-btn {
  position: relative; top: 1rem;
}

.footer {
  background-color: $mine-shaft;
  position: relative;
  padding: 30px 0 50px;
  &_logo {
    height: 50px;
    position: relative;
  }
  &_brand {
    display: block;
    &:before {
      transition: bottom ease .25s, background-color ease .25s;
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      top: 25px;
      left: 0;
      background: $fuel-yellow;
    }
    &:hover {
      border-bottom: none;
    }
    img {
      display: block;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
  }
  &_signup_inner {
    max-width: 750px;
    margin: 0 auto;
    form {
      position: relative;
      margin: 30px auto;
      max-width: 600px;
      display: block;
      width: 100%;
      input {
        display: inline-block;
        width: calc(100% - 100px);
        height: 60px;
        border: 1px solid #fff;
        margin: 0;
        padding: 0 15px;
        outline: 0;
        font: 500 1.15rem "Tenso";
       }
       button {
        display: inline-block;
        width: 95px;
        height: 60px;
        border: none;
        margin:  0 0 0 -4px;
        outline: 0;
        background-color: #fff;
        fill: $fuel-yellow;
        padding: 0px 25px;
        @include transition;
        &:hover {
          background-color: $fuel-yellow;
          fill: #fff;
        }
      }
    }
  }
  &_social {
    margin-top: 50px;
    margin-bottom: 50px;
    box-sizing: content-box;
    padding: 0;
    font: 700 1.625rem "Rift";
    text-align: center;
    ul {
      display: inline-block;
      margin: 0 auto;
      width: auto;
      color: #fff;
      padding: 0;
      li {
        list-style-type: none;
        width: 30px;
        height: 30px;
        color: #fff !important;
        float: left;
        margin: 0 20px;
        svg {
          fill: $fuel-yellow;
          @include transition();
          &:hover {
            fill: #fff;
          }
        }
      }
    }
  }

  &_nav {
    text-align: center;
    ul {
      display: inline-block;
      margin: 0 auto;
      width: auto;
      padding: 0;
      li {
        display: inline-block;
        list-style-type: none;
        margin: 0 20px;
        @media #{$sm} {
          margin: 0 10px;
        }
        @media #{$xs} {
          display: block;
          margin-bottom: 5px;
        }
         a {
          font: 700 1.125rem "Rift";
          color: #fff;
          text-decoration: none;
          @include transition();
          &:hover {
            color: $fuel-yellow;
          }
        }
      }
    }
  }
  .disclosure {
    font: 500 0.8rem "Tenso";
    color: #fff;
    text-align: center;
    padding: 30px 0 0;
    p {
      margin-bottom: 0;
    }
  }
}
